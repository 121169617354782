// ** React Imports
import { Box, Typography } from '@mui/material';
import * as React from 'react';

interface TextFieldErrorProps {
    message: string
}
const TextFieldError: React.FC<TextFieldErrorProps> = ({ message }) => {
  return (
    <Box sx={{ padding: '0rem' , lineHeight: "0rem" }}>
        <Typography variant='caption' color="error" fontWeight={500}>
            {message}
        </Typography>
    </Box>
  );
}

export default TextFieldError