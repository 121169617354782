// ** React Imports
import { ChangeEvent, FormEvent, MouseEvent, ReactNode, useState, useEffect } from 'react'

// ** Next Imports
import { useRouter } from 'next/router'

// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled } from '@mui/material/styles'
import MuiCard, { CardProps } from '@mui/material/Card'
import InputAdornment from '@mui/material/InputAdornment'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import InputTextField from 'src/components/common/form-elements/InputTextField'
import { useLocale } from 'src/lib/hooks/useLocal'
// ** Icons Imports
import { Eye, EyeOff } from 'react-feather'

// ** Configs
import themeConfig from 'src/@core/configs/themeConfig'

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout'

// ** Contexts
import { useAuth } from 'src/lib/contexts/AuthContext'

// **  Child Imports
import Alerts from 'src/components/common/feedback-and-dialog/Alerts'

// ** Helper Function Imports
import { hasAuthCookie, validateEmail } from 'src/lib/utils/helpers'
import { useFilter } from 'src/lib/contexts/FilterContext'

import { postRequest } from 'src/lib/core/api-client'
import { alertSeverites, apiURI } from 'src/lib/utils/constants'

interface State {
  password: string
  showPassword: boolean
}

// ** Styled Components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '28rem' }
}))

const LinkStyled = styled('a')(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main
}))

const LoginPage = () => {
  // ** State
  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false
  })
  const { login } = useAuth()
  const { t } = useLocale()
  const { setFiltersCtx } = useFilter()
  const [email, setEmail] = useState<string>('')
  const [credentailsInvalidMessage, setCredentailsInvalidMessage] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [forgotPasswordOpen, setforgotPasswordOpen] = useState<boolean>(false)
  const [resetEmail, setResetEmail] = useState<string>('')
  const [openAlert, setOpenAlert] = useState<string>('')
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [resetEmailNotValid, setResetEmailNotValid] = useState<boolean>(false)

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    if (hasAuthCookie()) router.replace('/')
  }, [])

  const validateCredentials = async () => {
    if (password === null || password === '' || !validateEmail(email)) {
      setCredentailsInvalidMessage(t('enter_valid_email_and_password'))
      return
    }
    const payload = {
      email: email.trim(),
      password: password
    }

    try {
      let response: any = await postRequest('/merchant-user/login', apiURI.AUTH_ENGINE, payload)
      if (!response?.data?.success) {
        if (response?.data?.error?.code === 'GE_001') {
          setCredentailsInvalidMessage(t('email_not_exists'))
        } else {
          setCredentailsInvalidMessage(t('invalid_email_or_password'))
        }
        return
      }
      response = response?.data?.data
      const authToken = response?.access_token
      const authTokenExpiry = response?.access_token_expires_at
      const refreshToken = response?.refresh_token
      const refreshTokenExpiry = response?.refresh_token_expires_at
      login(authToken, email, '', refreshToken, authTokenExpiry, refreshTokenExpiry)
      setFiltersCtx()
      router.replace('/')
    } catch (error: any) {
      handleAlerts(alertSeverites.SEVERITY_ERROR, t('something_went_wrong'))
    }
  }

  const handlePasswordChange = (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
    setPassword(event.target.value)
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleForgotPassword = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (!validateEmail(resetEmail)) {
      setResetEmailNotValid(true)
      return
    }
    // sendPasswordResetEmail(auth, resetEmail)
    //   .then(() => {
    //     setforgotPasswordOpen(false)
    //     setOpenAlert('success')
    //   })
    //   .catch(error => {
    //     setOpenAlert('error')
    //     console.error(error)
    //   })

    try {
      const payload = {
        email: resetEmail
      }
      await postRequest('/merchant-user/forgot-password', apiURI.AUTH_ENGINE, payload)
      setforgotPasswordOpen(false)
      setOpenAlert('success')
    } catch (e: any) {
      handleAlerts(alertSeverites.SEVERITY_ERROR, e?.message)
      console.error(e)
    }
  }

  // ** Alert Handlers ** //
  const handleAlerts = (severity: string, message?: string) => {
    setOpenAlert(severity)
    message && setAlertMessage(message)
  }

  const handleCloseAlert = (_event: any, reason: string) => {
    if (reason === 'clickaway') {
      return
    }
    handleAlerts('')
  }

  const handleEnterPress = (event: any) => {
    if (event?.key === 'Enter') {
      event.preventDefault()
      validateCredentials()
    }
  }

  return (
    <Box className='content-center'>
      <div className='flex flex-col items-center justify-center w-full gap-12'>
        <div className='flex flex-col items-center justify-center gap-1'>
          <img src='/images/shopflo-main_logo.svg' alt='Shopflo Logo' />
        </div>
        <form
          className='flex flex-col gap-4 w-1/2 max-w-[28rem]'
          noValidate
          autoComplete='off'
          onSubmit={e => e.preventDefault()}
        >
          <InputTextField
            textFieldId='email'
            inputPlaceholder={'Email'}
            inputType='text'
            inputMargin={'none'}
            inputProps={{
              style: {
                marginLeft: -16,
                marginRight: -16,
                paddingLeft: 14,
                paddingRight: 14,
                width: '104%'
              }
            }}
            isFullWidth={true}
            isRequired={true}
            inputValue={email}
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value)
            }}
            handleKeyDown={handleEnterPress}
            customClass='bg-white rounded-lg overflow-hidden'
          />
          <FormControl fullWidth>
            <OutlinedInput
              size='small'
              className='overflow-hidden bg-white rounded-lg'
              placeholder='Password'
              value={values.password}
              autoComplete='off'
              id='auth-login-password'
              onChange={handlePasswordChange('password')}
              type={values.showPassword ? 'text' : 'password'}
              inputProps={{
                style: {
                  marginLeft: -8,
                  marginRight: -8,
                  paddingLeft: 16,
                  paddingRight: 14,
                  width: '104%',
                  borderTopRightRadius: '0px',
                  borderBottomRightRadius: '0px'
                }
              }}
              onKeyDown={handleEnterPress}
              endAdornment={
                <button
                  className={`w-16 h-10 flex justify-center items-center transition duration-300 ${
                    values.showPassword ? 'bg-[#f0f0f0]' : 'bg-[#fafafa]'
                  } hover:bg-[#e0e0e0] -mr-2`}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  aria-label='toggle password visibility'
                >
                  {values.showPassword ? <EyeOff className='w-5 h-5' /> : <Eye className='w-5 h-5' />}
                </button>
              }
            />
          </FormControl>
          <div className='flex justify-center'>
            <Typography
              variant='body2'
              sx={{ color: '#f44336' }}
              className={`transition ease-in-out transform duration-200 ${
                credentailsInvalidMessage.length > 0 ? 'opacity-100' : 'opacity-0'
              }`}
            >
              {credentailsInvalidMessage}
            </Typography>
          </div>
          <Button
            id='login-login-button'
            onClick={validateCredentials}
            className='px-3 py-2 text-white rounded-lg bg-primary-dark'
            type='submit'
            variant='contained'
            sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          >
            Login
          </Button>
          <div className='flex justify-center'>
            <button
              id='login-reset-password-button'
              type='button'
              onClick={() => {
                setforgotPasswordOpen(true)
                setResetEmail(email)
              }}
              className='text-sm font-semibold border-b-2 text-coal-dark hover:border-primary-dark'
            >
              Reset password
            </button>
          </div>
        </form>
      </div>
      <Dialog
        open={forgotPasswordOpen}
        onClose={() => {
          setforgotPasswordOpen(false)
        }}
      >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='body2'>Enter your email address to get a reset password link.</Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Email Address'
            type='email'
            fullWidth
            variant='standard'
            value={resetEmail}
            onFocus={() => setResetEmailNotValid(false)}
            onChange={event => {
              setResetEmail(event.target.value)
            }}
          />
          <DialogContentText
            variant='body2'
            sx={{ color: '#f44336', opacity: `${resetEmailNotValid ? '100%' : '0%'}` }}
          >
            Email address is invalid!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setforgotPasswordOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            id="login-reset-password-send-email"
            disabled={!resetEmail.length}
            onClick={e => {
              handleForgotPassword(e)
            }}
          >
            Send Email
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alerts */}

      {openAlert.length ? (
        <Alerts
          open={true}
          duration={2000}
          closeHandler={handleCloseAlert}
          severity={openAlert}
          message={openAlert === 'success' ? 'Email Sent!' : alertMessage}
        />
      ) : null}
    </Box>
  )
}

LoginPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

export default LoginPage
