//TODO -- Need to maintain Loader's visibilty throw context to avoid declaring states in every component

// ** React Imports
import { Box, Typography } from '@mui/material';
import * as React from 'react';

interface TextInputLimitCounterProps {
    inputText: string | undefined | null
    limit: number
}
const TextInputLimitCounter: React.FC<TextInputLimitCounterProps> = ({ 
  inputText, 
  limit 
}) => {
  return (
    <Box sx={{ padding: '0rem', position: "absolute", top: 0, right: 0}}>
        <Typography variant='subtitle2' sx={{ fontSize: 12, fontWeight: 400 }}>
            {`${inputText?.length ?? 0}/${limit}`}
        </Typography>
    </Box>
  );
}

export default TextInputLimitCounter