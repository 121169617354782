// ** React Imports
import * as React from 'react'
import { InputAdornment, styled, TextField, Typography } from '@mui/material'
import TextFieldError from './TextFieldError'
import TextInputLimitCounter from './TextInputLimitCounter'

interface InputTextFieldProps {
  textFieldId: string
  inputValue?: any
  handleChange?: (value: any) => void
  inputType?: string
  isRequired?: boolean
  errorStatus?: boolean
  errorMessage?: string
  label?: string
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  inputProps?: any
  inputSize?: 'small' | 'medium'
  isFullWidth?: boolean
  isDisabled?: boolean
  inputModeType?: 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal'
  inputPlaceholder?: string
  inputVairant?: 'outlined' | 'filled' | 'standard'
  handleFocus?: (e: any) => void
  handleBlur?: (e: any) => void
  maxCharacter?: number
  characterCounter?: boolean
  handleOnClick?: (e: any) => void
  handleKeyDown?: (e: any) => void
  autoFocus?: boolean
  inputMargin?: 'none' | 'normal' | 'dense'
  customClass?: string
  autoComplete?: string
}

const Input = styled(TextField)(({ theme }) => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none'
  },
  '& input[type=number]': {
    MozAppearance: 'textfield'
  },
  '& input' : {
    borderRadius: '0px'
  }
}))

const InputTextField: React.FC<InputTextFieldProps> = ({
  textFieldId,
  isRequired = false,
  errorStatus = false,
  errorMessage = '',
  label = '',
  startIcon = <></>,
  endIcon = <></>,
  inputProps = {},
  inputSize = 'small',
  isFullWidth = true,
  inputValue,
  handleChange,
  isDisabled = false,
  inputType = 'text',
  inputModeType = 'text',
  inputPlaceholder = '',
  inputVairant = 'outlined',
  handleFocus,
  handleBlur,
  maxCharacter,
  characterCounter = false,
  handleOnClick,
  handleKeyDown,
  autoFocus = false,
  inputMargin = 'none',
  customClass = '',
  autoComplete = 'off'
}) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', width: isFullWidth ? '100%' : '', position: 'relative' }}
      className={customClass}
    >
      <Typography
        variant='body2'
        sx={{
          fontWeight: 500,
          fontSize: 12,
          whiteSpace: 'nowrap'
        }}
      >
        {label}
      </Typography>
      <Input
        className={`rounded-lg shadow-sm ${isDisabled ? 'bg-[#F5F5F5]' : ''}`}
        id={textFieldId}
        required={isRequired}
        error={errorStatus}
        // helperText={errorStatus ? errorMessage : countingText}
        margin={inputMargin}
        InputProps={{
          autoComplete: autoComplete,
          endAdornment: <InputAdornment position='end'>{endIcon}</InputAdornment>,
          startAdornment: <InputAdornment position='start'>{startIcon}</InputAdornment>,
          inputProps: inputProps
        }}
        size={inputSize}
        fullWidth={isFullWidth}
        value={inputValue}
        onChange={handleChange}
        disabled={isDisabled}
        type={inputType}
        inputMode={inputModeType}
        placeholder={inputPlaceholder}
        variant={inputVairant}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleOnClick}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
      />
      {errorStatus ? (
        <TextFieldError message={errorMessage} />
      ) : maxCharacter && characterCounter ? (
        <TextInputLimitCounter inputText={inputValue} limit={maxCharacter} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default InputTextField
